<h1>Time Card</h1>
<form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
    <label *ngIf="this.members.length > 1">
      Member:
      <select formControlName="memberId" class="form-control" 
      (change)="this.getTickets()"
        [ngClass]="{'invalid_text': myForm.get('memberId').invalid && (myForm.dirty || myForm.touched)}">
        <option *ngFor="let member of this.members" [value]="member.id">
          {{ member.knownAs }}
        </option>
      </select>
    </label>
    <label>
      Project:
      <select formControlName="projectId" class="form-control">
        <option *ngFor="let project of this.projects" [value]="project.id">
          {{project.code}} / {{project.title}}  
        </option>
      </select>
    </label>
    <label>
      Ticket:
      <input formControlName="ticketTitle" class="form-control" placeholder="Type partial Title">
    </label>
    <label>
      Notes:
      <input formControlName="notes" class="form-control" placeholder="Type partial Notes">
    </label>  
    <label>
      Start Date:
      <input
        type="text"
        class="form-control"
        formControlName="startTime" 
        bsDatepicker
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
      />
    </label>
    <label>
      End Date:
      <input
      type="text"
      class="form-control"
      formControlName="endTime" 
      bsDatepicker
      [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
    />
    </label>
  <button type="submit" [disabled]="myForm.invalid && this.members.length > 1">Search</button>
</form>

<table *ngIf="this.projectDates != null && this.projectDates.length > 0 " border="1">
  <thead>
    <tr>
      <th style="padding:0 15px 0 15px;">Project/Date</th>
      <th *ngFor="let date of this.uniqueDates" style="padding:0 15px 0 15px;">
        {{ this.datePipe.transform(date, 'EEEE (yyyy/MM/dd)') }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let project of this.uniqueProjects; let projectIndex = index;">
      <td style="padding:0 15px 0 15px;">
        {{ project.id != null ? project.code + ":" + project.title : "No Project" }}
      </td>
      <td *ngFor="let date of this.uniqueDates; let dateIndex = index;" style="padding:0 15px 0 15px;">
        {{ this.projectDates[projectIndex][dateIndex] }}
      </td>
    </tr>
  </tbody>
</table>
<div>Total Hours: {{this.totalHours}}</div>
<br/>
<hr/>
<div>Raw data:</div>
<table *ngIf="this.timeBlocks != null">
  <tr>
    <th>
      Project
    </th>
    <th>
      Ticket
    </th>
    <th>
      Notes
    </th>
    <th>
      Start Time
    </th>
    <th>
      End Time
    </th>
  </tr>
  <tr *ngFor="let timeBlock of this.timeBlocks">
    <td *ngIf="timeBlock?.ticket?.project != null" style="vertical-align:top">{{ timeBlock.ticket?.project?.title }} / {{ timeBlock.ticket?.project?.code }}</td>
    <td *ngIf="timeBlock?.ticket?.project == null" style="vertical-align:top">No Project</td>
    <td style="vertical-align:top">{{ timeBlock.ticket?.title }}</td>
    <td style="vertical-align:top">{{ timeBlock.notes }}</td>
    <td style="vertical-align:top">{{ this.datePipe.transform(timeBlock.startTime, 'yyyy/MM/dd HH:mm') }}</td>
    <td style="vertical-align:top">{{ this.datePipe.transform(timeBlock.endTime, 'yyyy/MM/dd HH:mm') }}</td>
  </tr>
</table>
