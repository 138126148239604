<div class="container mt-5">
  <div *ngIf="authService.decodedToken">
    Welcome to TimeSink!<br />
    <br />
    <ol>
      <li>Subscriptions - only available to the subscription owner
        <ul>
          <li>Allows you to create projects</li>
          <li>Allows you to invite members by email address to said projects</li>
        </ul>
      </li>
      <li>Projects:
        <ul>
          <li>A grouping of work - typically used to charge your time against a specific account code</li>
          <li>You must mark projects as "ACCEPTED" in order to use them to create <b>Tickets</b></li>
        </ul>
      </li>
      <li>Tickets:
        <ul>
          <li>A specific item of work that <b>you</b> create, which is viewable to your Subscription owner in the form of a time card</li>
          <li>All hours worked on a ticket are displayed in the "Completed Hours" column</li>
          <li>The <b>Link Number</b> in the ticket will be be used to generate a link provided by the Subscription owner</li>
        </ul>
      </li>
      <li>TimeSheet:
        <ul>
          <li>Use this to input the time you work in 15 minute intervals</li>
          <li>You must either specify a specific ticket <b>or</b> enter a descriptive note</li>
          <li>Subscription Owners can only view timesheet entries for tickets assigned to one of their projects</li>
        </ul>
      </li>
      <li>TimeCard:
        <ul>
          <li>Filter Timesheet entries by Project, Ticket, Notes, Start Time, and/or End Time</li>
          <li>All matching Timesheet entries will be formatted in a Project-Per-Day table</li>
          <li>The raw TimesSheet entries will also be displayed below the Project-Per-Day table</li>
          <li>It is recommended that you save the timecard to your local system by copying-and-pasting the TimeCard table/raw data into Excel for safekeeping </li>
        </ul>
      </li>
    </ol>
  </div><br/>
  
<!--   <div class="justify-content-center">
    TimeSink allows logging in with social media (Google and Facebook). If you currently log in with 
    an email and password, you should first go to your Member profile to link with social media.<br />
    <b>If your Member profile is not linked, logging in with social media will create a new account!</b><br/><br/>
    Feedback to <a href="mailto:administrator@wyssweb.com">TimeSink Administrator</a>
  </div> -->
</div>