import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketService } from '../_services/ticket.service';
import { TimeBlockService } from '../_services/timeBlock.service';
import { TimeBlock } from 'src/app/_models/timeBlock';
import { Ticket } from 'src/app/_models/ticket';
import { DatePipe } from '@angular/common';
import { AlertifyService } from '../_services/alertify.service';
import { TimeBlockForUpdate } from '../_models/timeBlockForUpdate';
import { TimeblockComponent } from './timeblock/timeblock.component';
import { TimeBlockQuery } from '../_models/timeBlockQuery';
import { AuthService } from '../_services/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit {
  
  public totalHours: number = 0;
  public timeblocks: TimeBlock[];
  public tickets: Ticket[] ;
  public timeBlock: TimeBlock = {id: 0, ticket: null, startTime: null, endTime: null, notes: '', completedHours: 0 };
  public currentDate: Date;
  public nextDate: Date;
  time: Date = new Date();

  myForm: UntypedFormGroup;

  private query: TimeBlockQuery = { memberId: null, projectId: null, ticket: null, notes: null, startTime: null, endTime: null };

  private timeBlockForUpdate: TimeBlockForUpdate;

  constructor(private route: ActivatedRoute
    , private router: Router
    , private authService: AuthService
    , private dialog: MatDialog
    , private fb: UntypedFormBuilder
    , private ticketService: TicketService
    , private timeblockService: TimeBlockService
    , private alertify: AlertifyService
    , public datePipe: DatePipe) 
  { }

  ngOnInit() {
    var param = this.route.snapshot.queryParamMap.get('date');
    if (param) {
      this.currentDate = new Date(param + ' 00:00:00');
      this.nextDate = new Date(param + ' 00:00:00');
    } else {
      this.currentDate = new Date();
      this.currentDate.setMinutes(0);
      this.nextDate = new Date();
      this.nextDate.setMinutes(0);
    }

    this.nextDate = new Date(this.nextDate.setDate(this.nextDate.getDate() + 1));

    this.timeBlock.startTime = this.currentDate;
    this.timeBlock.endTime = this.currentDate;

    this.query.memberId = this.authService.currentMember.id;
    this.query.startTime = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.query.endTime = this.datePipe.transform(this.nextDate, 'yyyy-MM-dd');

    this.myForm = this.fb.group({
      currentDate: [this.currentDate, Validators.required]
    });

    this.myForm.valueChanges.subscribe(data => 
      this.changeDate(data.currentDate)
    );

    this.timeblockService.getTimeBlocks(this.query)
      .subscribe(timeBlocks => {
        this.timeblocks = timeBlocks;
        this.timeblocks.forEach(timeblock => {
          this.totalHours += timeblock.completedHours;
        });
      }, error => {
        this.alertify.error("Error retrieving your timesheet");
      });

    this.ticketService.getTickets(true).subscribe(result => {
      this.tickets = result;
    }, error=> {
      this.alertify.error("Error retrieving your tickets");
    });
  }

  deleteTimeBlock(timeBlockId: number) {
    this.timeblockService.deleteTimeBlock(timeBlockId).subscribe(result => {
      location.reload();
    }, error => {
      this.alertify.error('Could not delete TimeBlock ' + timeBlockId);
    });
  }

  public editTimeBlock(timeblock: TimeBlock) {
    if (timeblock != null) {
      this.timeBlockForUpdate = {
        id: timeblock.id,
        ticketId: timeblock.ticket?.id,
        ticket: timeblock.ticket,
        notes: timeblock.notes,
        startTime: this.datePipe.transform(timeblock?.startTime, 'yyyy-MM-dd HH:mm'),
        endTime: this.datePipe.transform(timeblock?.endTime, 'yyyy-MM-dd HH:mm')
      }
    } else {
      this.timeBlockForUpdate = {
        id: 0,
        ticketId: null,
        ticket: null,
        notes: '',
        startTime: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd HH') + ':00',
        endTime: this.datePipe.transform(this.currentDate, 'yyyy-MM-dd HH') + ':00'
      }
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: 1,
      title: 'Timeblock',
      currentDate: this.currentDate,
      timeBlock: this.timeBlockForUpdate,
      tickets: this.tickets
    };

    //https://blog.angular-university.io/angular-material-dialog/
    const dialogRef = this.dialog.open(TimeblockComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {
          console.log("Dialog output:", data);
          location.reload();
        }
    );    
  }

  updateTimeBlock(timeBlock: TimeBlock) {
    this.timeBlockForUpdate = {
      id: this.timeBlock.id,
      ticketId: this.timeBlock.ticket?.id,
      ticket: this.timeBlock.ticket,
      notes: this.timeBlock.notes,
      startTime: this.datePipe.transform(this.timeBlock?.startTime, 'yyyy-MM-dd HH:mm'),
      endTime: this.datePipe.transform(this.timeBlock?.endTime, 'yyyy-MM-dd HH:mm')
    }
    
    this.timeblockService.updateTimeBlock(this.timeBlockForUpdate).subscribe(result => {
      location.reload();
    }, error => {
      this.alertify.error("Could not create new TimeBlock");
    })
  }

  getLink(ticket: Ticket) {
    if (ticket === null || ticket.linkNumber === null) return '';
    var result = ticket.project.url.replace('[linkNumber]', ticket.linkNumber);
    return result;
  }

  highlightRow(index: number) {
    if (index == 0) { return false; }
    let endDate = new Date(this.timeblocks[index-1].endTime.valueOf() + '.000Z');
    let startDate = new Date(this.timeblocks[index].startTime.valueOf() + '.000Z');

    if (endDate.valueOf() != startDate.valueOf()) {
      return true;
    }
    return false;
  }

  changeDate(date: Date) {
    console.log('/timesheet?date=' + this.datePipe.transform(date, 'yyyy-MM-dd'));
    this.router.navigateByUrl('timesheet?date=' + this.datePipe.transform(date, 'yyyy-MM-dd')).then(() => {
      window.location.reload();
    });
    //this.router.navigate(['timesheet'], { queryParams: {date: this.datePipe.transform(date, 'yyyy-MM-dd')}});
  }
}
