import { Component, OnInit } from '@angular/core';
import { TimeBlock } from '../_models/timeBlock';
import { SubscriptionService } from '../_services/subscription.service';
import { AlertifyService } from '../_services/alertify.service';
import { TimeBlockQuery } from '../_models/timeBlockQuery';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Ticket } from '../_models/ticket';
import { Project } from '../_models/project';
import { DatePipe } from '@angular/common';
import { TimeBlockService } from '../_services/timeBlock.service';
import { Member } from '../_models/member';
import { AuthService } from '../_services/auth.service';
import { TicketService } from '../_services/ticket.service';

@Component({
  selector: 'app-time-card',
  templateUrl: './time-card.component.html',
  styleUrls: ['./time-card.component.css']
})
export class TimeCardComponent implements OnInit {
  public timeBlocks: TimeBlock[];
  public tickets: Ticket[] = [{ id: null, title: 'Select a Member', project: null, linkNumber: null, member: null, estimatedHours: null, completedHours: null, isActive: null }];
  public projects: Project[] = [{ id: null, title: 'Select a Member', code: 'Title', url: '', isActive: null }];
  public members: Member[] = [{ id: null, knownAs: 'Select a Member', isActive: null, logins: null, provider: null }];

  public query: TimeBlockQuery = { memberId: null, projectId: null, ticket: null, notes: null, startTime: null, endTime: null };
  public myForm: UntypedFormGroup;

  public uniqueDates: any[];
  public uniqueProjects: Project[] = [];
  
  public projectDates: any[];
  public totalHours: number;

  constructor(private ticketService: TicketService
    , private authService: AuthService
    , private timeblockService: TimeBlockService
    , private subscriptionService: SubscriptionService
    , private alertify: AlertifyService
    , private fb: UntypedFormBuilder
    , public datePipe: DatePipe ) { null }

  ngOnInit() {
    
    this.subscriptionService.getSubscriptionMembers().subscribe(result => {
      this.members = this.members.concat(result);
      if (this.members.length < 2) {
        this.query.memberId = this.authService.currentMember.id;
      }
    });

    this.timeblockService.getTimeBlocks(this.query).subscribe(result => {
      this.timeBlocks = result;
      this.displayTimeCard();
    }, error => {
      this.alertify.error(error);
    });

    this.myForm = this.fb.group({
      memberId: [this.query.memberId, Validators.required],
      projectId: this.query.projectId,
      ticketTitle: this.query.ticket,
      notes: this.query.notes,
      startTime: this.query.startTime,
      endTime: this.query.endTime
    });
  }

  onSubmit(form: UntypedFormGroup) {
    if (this.members.length > 1)
      this.query.memberId = form.value.memberId
    this.query.projectId = form.value.projectId;
    if (isNaN(this.query.projectId)) {
      this.query.projectId = null;
    }
    this.query.ticket = form.value.ticketTitle;

    this.query.notes = form.value.notes;
    this.query.startTime = form.value.startTime;
    this.query.endTime = form.value.endTime;

    this.timeblockService.getTimeBlocks(this.query).subscribe(result => {
      this.timeBlocks = result;
      this.displayTimeCard();
    });
  }

  getTickets(form: UntypedFormGroup) {
    this.tickets = this.tickets.filter(t => t.id == null);
    this.projects = this.projects.filter(p => p.id == null);
    this.projects[0].title = "Select a Member";
    this.projectDates.splice(0);
    this.totalHours = null;

    if (this.myForm.value.memberId != null && this.myForm.value.memberId != "null") {
      this.ticketService.getTicketsByMember(this.myForm.value.memberId).subscribe(tickets => {
        this.projects[0].title = "Select a Project";
        this.myForm.get('projectId').reset(); 
        this.tickets = this.tickets.concat(tickets);
        this.tickets.forEach(ticket => {
          if (ticket.project != null && !(this).projects.find(project => project.id == ticket.project?.id)) {
            this.projects.push(ticket.project);
          }
        });
      });
    }
  }

  displayTimeCard() {
    this.getUniqueDates();
    this.getUniqueProject();
    this.generateTimeCard();
    this.appendListOfProjectTitles()
  }

  getUniqueDates() {
    var uniqueDates = [];
    for(let i = 0; i< this.timeBlocks.length; i++){    
        let startDate = this.datePipe.transform(this.timeBlocks[i].startTime, 'yyyy/MM/dd');
        let endDate = this.datePipe.transform(this.timeBlocks[i].endTime, 'yyyy/MM/dd');
        if(uniqueDates.indexOf(startDate) === -1){
          uniqueDates.push(startDate);        
        }
        if(uniqueDates.indexOf(endDate) === -1){
          uniqueDates.push(endDate);        
        }       
    }
    this.uniqueDates = uniqueDates;
  }

  getUniqueProject() {
    var uniqueProjs = this.projects.filter(project => project.id == -1);

    for(let i = 0; i< this.timeBlocks.length; i++){
      let project = this.timeBlocks[i].ticket?.project;
      if (typeof project === 'undefined') {
        let existingProject = uniqueProjs.find(up => up.code === '');
        if (existingProject == null) {
          uniqueProjs.push({ id: null, title: '', code: '', url: '', isActive: null });
        }        
      } else {
        let existingProject = uniqueProjs.find(up => up.code == project?.code);
        if (existingProject == null) {
          uniqueProjs.push(project);        
        }
      }
    }

    this.uniqueProjects = uniqueProjs;
  }

  generateTimeCard() {
    this.totalHours = 0;
    let self = this;

    this.projectDates = new Array(this.uniqueProjects.length);
    for (var tc = 0; tc < this.uniqueProjects.length; tc ++) {
      this.projectDates[tc] = new Array(this.uniqueDates.length);
    }

    for (let dateIndex = 0; dateIndex < this.uniqueDates.length; dateIndex++) {
      for (let projectIndex = 0; projectIndex < this.uniqueProjects.length; projectIndex++) {
        let entries = this.timeBlocks.filter(timeBlock => this.datePipe.transform(timeBlock.startTime, 'yyyy/MM/dd') === this.uniqueDates[dateIndex] 
          && (timeBlock.ticket?.project?.code == this.uniqueProjects[projectIndex].code
            || timeBlock.ticket?.project == null && this.uniqueProjects[projectIndex].id == null)
        );

        let projectDateHours = 0.0;
        entries.forEach(entry => {
          let startTime = new Date(entry.startTime.valueOf() + '.000Z');
          let endTime = new Date(entry.endTime.valueOf() + '.000Z');
          const durationInMilliseconds = endTime.valueOf() - startTime.valueOf();
          const minutes = Math.floor(durationInMilliseconds / (1000 * 60));

          this.totalHours += minutes/60;
          projectDateHours += minutes/60;
        });
        
        this.projectDates[projectIndex][dateIndex] = projectDateHours;      
      }
    }
  }

  appendListOfProjectTitles() {
    let self = this;
    this.uniqueProjects.forEach(function(project, index) {
      let timesheetsUsingProjectCode = self.timeBlocks.filter(ts => ts.ticket?.project?.code == project.code);
      var timesheetProjectTitles = timesheetsUsingProjectCode.map(timesheet => {
        return "[" + timesheet?.ticket?.project?.title + "]";
      });
      var uniqueTitles = timesheetProjectTitles.filter((value, index, array) => array.indexOf(value) === index);
      self.uniqueProjects[index].title = "" + uniqueTitles;
    });
  }
}
