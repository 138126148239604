import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MemberProject } from '../_models/memberProject';
import { MemberProjectForUpdate } from '../_models/memberProjectForUpdate';
import { ProjectService } from '../_services/project.service';
import { AlertifyService } from '../_services/alertify.service';
import { MyProjectComponent } from './my-project/my-project.component';
import { MemberProjectStatus } from '../_models/memberProjectStatus';

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.css']
})
export class MyProjectsComponent implements OnInit {
  public memberProjects: MemberProject[];
  private memberProjectForUpdate: MemberProjectForUpdate;

  public statuses: MemberProjectStatus[];
  public showActive: boolean = true;
  public subscriptionId: number = 0;
  public myForm: FormGroup;
  public filterStatus = 'ACCEPTED';
  public filterTitle = null;
  public filterCode = null;
  public filterUrl = null;

  constructor(private route: ActivatedRoute
    , private modalService: NgbModal
    , private projectService: ProjectService
    , private alertify: AlertifyService
    , private fb: FormBuilder ) { }

  ngOnInit() {
    this.statuses = this.projectService.getProjectStatus();
    this.route.data.subscribe(data => {
      this.memberProjects = data.member;
    });
    this.myForm = this.fb.group({
      status: [this.filterStatus],
      title: [this.filterTitle],
      code: [this.filterCode],
      url: [this.filterUrl]
    });
  }

  public editMemberProject(memberProject: MemberProject) {
    this.memberProjectForUpdate = {
      id: memberProject.id,
      projectId: memberProject.project.id,
      memberId: memberProject.member?.id,
      emailAddress: memberProject.emailAddress,
      statusCode: memberProject.statusCode
    };

    const modalRef = this.modalService.open(MyProjectComponent);
    modalRef.componentInstance.memberProject = this.memberProjectForUpdate;
    modalRef.result.then((result: any) => {
      console.log(result);
      location.reload();
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }

  public onFilter(form: FormGroup) {
    this.filterStatus = form.value.status;
    this.filterTitle = form.value.title;
    this.filterCode = form.value.code;
    this.filterUrl = form.value.url;
  }
}
