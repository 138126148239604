import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { BlockUIModule } from 'ng-block-ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AuthService } from './_services/auth.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
//import { RegisterComponent } from './authentication/register/register.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { AlertifyService } from './_services/alertify.service';
import { appRoutes } from './routes';
import { AuthGuard } from './_guards/auth.guard';
import { MemberService } from './_services/member.service';
//import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { TicketListResolver } from './_resolvers/ticket-list.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordComponent } from './authentication/password/password.component';
//import { LoginComponent } from './authentication/login/login.component';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { TicketListComponent } from './ticket-list/ticket-listcomponent';
import { ProjectMaintenanceComponent } from './project-maintenance/project-maintenance.component';
import { ProjectMaintenanceResolver } from './_resolvers/project-maintenance.resolver';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { TimeblockComponent } from './timesheet/timeblock/timeblock.component';
import { EditTicketComponent } from './ticket-list/edit-ticket/edit-ticket.component';
import { ProjectEditComponent } from './project-maintenance/project-edit/project-edit.component';
import { MemberProjectsMaintenanceComponent } from './project-maintenance/member-projects-maintenance/member-projects-maintenance.component';
import { EditMemberProjectComponent } from './project-maintenance/member-projects-maintenance/edit-member-project/edit-member-project.component';
import { MyProjectsComponent } from './my-projects/my-projects.component';
import { MemberProjectsResolver } from './_resolvers/member-projects.resolver';
import { MyProjectComponent } from './my-projects/my-project/my-project.component';
import { TimeCardComponent } from './time-card/time-card.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';

export function tokenGetter() {
   return localStorage.getItem('token');
}
@NgModule({ 
    declarations: [
        AppComponent,
        NavComponent,
        HomeComponent,
        //MemberEditComponent,
        //RegisterComponent,
        //LoginComponent,
        ConfirmComponent,
        PasswordComponent,
        TicketListComponent,
        ProjectMaintenanceComponent,
        ProjectEditComponent,
        MemberProjectsMaintenanceComponent,
        EditMemberProjectComponent,
        TimesheetComponent,
        TimeblockComponent,
        EditTicketComponent,
        MyProjectsComponent,
        MyProjectComponent,
        TimeCardComponent
    ],
    bootstrap: [
        AppComponent
    ], 
    imports: [BrowserModule,
        ReactiveFormsModule,
        SharedModule,
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        TabsModule.forRoot(),
        NgbModule,
        RouterModule.forRoot(appRoutes),
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: ['localhost:5000']
            }
        }),
        BlockUIModule.forRoot(),
        DragDropModule,
        MaterialModule,
        BrowserAnimationsModule], 
    providers: [
        AuthService,
        ErrorInterceptorProvider,
        AlertifyService,
        AuthGuard,
        PreventUnsavedChanges,
        MemberService,
        MemberEditResolver,
        TicketListResolver,
        DatePipe,
        ProjectMaintenanceResolver,
        MemberProjectsResolver,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              lang: 'en',
              providers: [
                {
                  id: GoogleLoginProvider.PROVIDER_ID,
                  provider: new GoogleLoginProvider(
                    '901513166970-tje861p33v958oo2oefiprgalandto9m.apps.googleusercontent.com'
                  )
                },
                {
                  id: FacebookLoginProvider.PROVIDER_ID,
                  provider: new FacebookLoginProvider('175633272520167')
                }
              ],
              onError: (err) => {
                console.error(err);
              }
            } as SocialAuthServiceConfig,
          },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
