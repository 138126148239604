<block-ui>
  <div class="container" *ngIf="this.loaded && displayMode==='' && this.loginForm.get('memberId').value !== 0">
    <div class="row justify-content-center">
      <button type="button" class="loginBtn loginBtn--email" (click)="displayMode='email'">Login with Email</button>
    </div>
    <div class="row justify-content-center">
      <!-- <button type="button" class="loginBtn loginBtn--google" (click)="signInWithGoogle()">Login with Google</button> -->
      <asl-google-signin-button type='icon' size='medium'></asl-google-signin-button>
    </div>
    <div class="row justify-content-center">
      <button type="button" class="loginBtn loginBtn--facebook" (click)="signInWithFB()">Login with Facebook</button>
    </div>
  </div>
  <div class="container" *ngIf="this.loaded && displayMode==='email' && this.loginForm.get('memberId').value !== 0">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <form [formGroup]="loginForm" class="form-inline my-2 my-lg-0" (ngSubmit)="login()">
          <div class="form-group row">
            <div class="col-md-4">
              <strong>Login Email:</strong>
            </div>
            <div class="col-md-8">
              <input formControlName="email" class="form-control mr-sm-2" type="text" name="email" 
                [ngClass]="{'is-invalid': loginForm.get('email').errors && loginForm.get('email').touched}"
                placeholder="Email Address">
              <div class="invalid-feedback">Please enter a valid email address</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <strong>Password</strong>
            </div>
            <div class="col-md-8">
              <input formControlName="password" class="form-control mr-sm-2" type="password" name="password" 
                [ngClass]="{'is-invalid': loginForm.get('password').errors && loginForm.get('password').touched}"
                placeholder="Password">
                <div class="invalid-feedback"
                *ngIf="loginForm.get('password').hasError('required')
                  && loginForm.get('password').touched">
                  Password is required
                </div>
                <div class="invalid-feedback"
                  *ngIf="loginForm.get('password').hasError('minlength')
                  && loginForm.get('password').touched">
                    Password must be at least {{this.passwordMinLength}} characters
                </div>
                <div class="invalid-feedback"
                  *ngIf="loginForm.get('password').hasError('maxlength')
                  && loginForm.get('password').touched">
                    Password cannot exceed [[this.passwordMaxLength]] characters
                </div>
              </div>
            </div>
          <div class="form-group row justify-content-center">
            <div class="col col-sm-3"><button class="btn btn-success my-2 my-sm-0" type="submit">Login</button></div>
            <div class="col col-sm-3"><button class="btn btn-warning my-2 my-sm-0" type="button" (click)="reset('')">Cancel</button></div>
            <div class="col col-sm-3"><a href="#" class="text-break" (click)="resetPassword()">Reset Password</a></div>
            <div class="col col-sm-3"><a href="#" class="text-break" (click)="newAccount()">New Account</a></div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="displayMode==='register'" class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h1>Don't have an account yet?  Sign up below!</h1>
        <app-register (cancelled)="reset('email')"></app-register>
      </div>
    </div>
  </div>
  
</block-ui>