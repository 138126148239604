import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberProjectForUpdate } from 'src/app/_models/memberProjectForUpdate';
import { MemberProjectStatus } from 'src/app/_models/memberProjectStatus';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-my-project',
  templateUrl: './my-project.component.html',
  styleUrls: ['./my-project.component.css']
})
export class MyProjectComponent implements OnInit {
  @Input() public memberProject: MemberProjectForUpdate;

  private statuses: MemberProjectStatus[];

  public editMemberProject: MemberProjectForUpdate;
  public modalTitle: string = "Member Project: ";
  public myForm: UntypedFormGroup;
  public statusList: MemberProjectStatus[];

  constructor(public activeModal: NgbActiveModal,
    private alertify: AlertifyService,
    private fb: UntypedFormBuilder, 
    private projectService: ProjectService) { }

  ngOnInit() {
    this.statuses = this.projectService.getProjectStatus();

    this.editMemberProject = {
      id: this.memberProject.id,
      projectId: this.memberProject.projectId,
      memberId: this.memberProject?.memberId,
      emailAddress: this.memberProject?.emailAddress,
      statusCode: this.memberProject?.statusCode
    };

    if (this.editMemberProject.id > 0) {
      this.statusList = this.statuses.filter(s => s.for === "user" || s.statusCode === this.editMemberProject.statusCode);
    }

    this.myForm = this.fb.group({
      statusCode: [this.editMemberProject?.statusCode]
    });
  }
  
  cancel() {
    this.activeModal.dismiss();
  }

  onSubmit(form: UntypedFormGroup) {
    if (form.valid) {
      this.editMemberProject.statusCode = form.value.statusCode;

      this.projectService.updateMemberProject(this.editMemberProject).subscribe(result => {
        this.activeModal.close(result);
      }, error => {
        this.alertify.error(error);
      });
    }
  }
}
